@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: rgba(23, 23, 23, 1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track {
  background: rgb(50, 50, 50);
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(200, 200, 200);
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.home {
  background: linear-gradient(
    300deg,
    rgba(23, 23, 23, 1) 50%,
    rgba(38, 38, 38, 1) 50%
  );
  background-color: rgba(38, 38, 38, 1);
  background-position: 3vh;
  background-repeat: no-repeat;
}

.btn {
  text-align: center;
  display: inline-block;
  padding: 5px 20px;
  cursor: pointer;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.btn:before {
  content: '';
  position: absolute;
  width: 0;
  background: #fff;
  left: 45%;
  height: 1px;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.7;
}

.btn:hover:before {
  width: 100%;
  left: 0;
}
